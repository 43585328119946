<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="
          background-image: url('TBS-Digitalisation-grant-website-banner_991-x-331_C-1140x380.jpg');
          background-size: contain;
        "
      >
        <div
          style="    vertical-align: bottom;
          height: 142px;
    padding: 50px 15px;"
        ></div>
      </div>
    </div>

    <div class="container sales-enquiry">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="page-content">
            <form
              class="form-inquiry"
              method="post"
              action="http://tbs.com.my/testing2/index.php?route=api/custom/smegrantapply"
            >
              <h1 style="    text-align: center;color: green;">
                {{ $route.query.m }}
              </h1>
              <h5>
                Additional information we request for application of SME Grant
              </h5>

              <div class="form-group row">
                <div class="col-sm-3">
                  Company Name<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    id="name"
                    name="companyname"
                    required=""
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3">
                  Company Contact Name<span class="acs-red">*</span>:
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    name="company_contact_name"
                    required=""
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  Phone No<span class="acs-red">*</span>:
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    name="phonenumber"
                    required=""
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  Sales Person In Charge Name<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    name="sales_person_in_charge_name"
                    required=""
                  />
                </div>
              </div>
              <h5>Application Information Required</h5>
              <div class="form-group row">
                <div class="col-sm-3">
                  Date of incorporation<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    name="date_of_incorporation"
                    required=""
                    type="date"
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  Date of Commencement<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    name="date_of_commencement"
                    required=""
                    type="date"
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  No of Full-time employee<span class="acs-red">*</span> :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    name="no_of_full_time_employee"
                    required=""
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  No of Part time employee<span class="acs-red">*</span> :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    name="no_of_part_time_employee"
                    required=""
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  Gross yearly income RM<span class="acs-red">*</span> :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    name="gross_yearly_income_rm"
                    required=""
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  One of Director mobile phone no.<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    name="director_mobile_phone_no"
                    required=""
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  One of Director Academic Qualification<span class="acs-red"
                    >*</span
                  >
                  :
                </div>
                <div class="col-sm-9">
                  <select
                    class="form-control input-sm"
                    name="director_academic_qualification"
                  >
                    <option value="Certificate">Certificate</option
                    ><option value="Diploma">Diploma</option>
                    <option value="Degree">Degree</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                <div class="col-sm-3"></div>
                <div class="col-sm-1">
                  Other:
                </div>
                <div class="col-sm-8">
                  <input
                    class="form-control input-sm"
                    name="other"
                    required=""
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  One of Director email<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control input-sm"
                    name="director_email"
                    required=""
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3">
                  One of Director Residential Type<span class="acs-red">*</span>
                  :
                </div>
                <div class="col-sm-9">
                  <select
                    class="form-control input-sm"
                    name="director_residential_type"
                    ><option value="Own">Own</option
                    ><option value="Rent">Rent</option>
                  </select>
                </div>
              </div>
              <h5>Document Submission Check List</h5>
              <div class="form-group row">
                <div class="col-sm-3">Tick (✓)</div>
                <div class="col-sm-9">
                  <div class="checkbox">
                    <label class="">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="document_submission_check_list[]"
                        value="Authorized signature application & declaration form with company chop"
                      />
                      Authorized signature application & declaration form with
                      company chop
                    </label>
                  </div>

                  <div class="checkbox">
                    <label class="">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="document_submission_check_list[]"
                        value="Photocopy of director’s IC (all directors)"
                      />
                      Photocopy of director’s IC (all directors)
                    </label>
                  </div>

                  <div class="checkbox">
                    <label class="">
                      Sdn Bhd - CTC of Business Registration Licenses (Full set
                      of SSM)
                    </label>
                  </div>

                  <div class="checkbox">
                    <label class="ml-4">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="document_submission_check_list[]"
                        value="Form 9 (Section 17)"
                      />
                      Form 9 (Section 17)
                    </label>
                  </div>

                  <div class="checkbox">
                    <label class="ml-4">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="document_submission_check_list[]"
                        value="Form 24 (Section 78)"
                      />
                      Form 24 (Section 78)
                    </label>
                  </div>
                  <div class="checkbox">
                    <label class="ml-4">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="document_submission_check_list[]"
                        value="Form 32A (Section 105) (If Applicable)"
                      />
                      Form 32A (Section 105) (If Applicable)
                    </label>
                  </div>
                  <div class="checkbox">
                    <label class="ml-4">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="document_submission_check_list[]"
                        value="Form 44 (Section 46)"
                      />
                      Form 44 (Section 46)
                    </label>
                  </div>
                  <div class="checkbox">
                    <label class="ml-4">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="document_submission_check_list[]"
                        value="Form 49 (Section 58)"
                      />
                      Form 49 (Section 58)
                    </label>
                  </div>
                  <div class="checkbox">
                    <label class="ml-4">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="document_submission_check_list[]"
                        value="M&A"
                      />
                      M&A
                    </label>
                  </div>

                  <div class="checkbox">
                    <label class="">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="document_submission_check_list[]"
                        value="Enterprise (Sole Proprietor / Partnership / LLP) – CTC full set of Borang D"
                      />
                      Enterprise (Sole Proprietor / Partnership / LLP) – CTC
                      full set of Borang D
                    </label>
                  </div>
                  <div class="checkbox">
                    <label class="">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="document_submission_check_list[]"
                        value="Latest 2 months bank statement (The most active bank)"
                      />
                      Latest 2 months bank statement (The most active bank)
                    </label>
                  </div>
                  <div class="checkbox">
                    <label class="">
                      <input
                        type="checkbox"
                        class="checkbox os_group_pc"
                        name="document_submission_check_list[]"
                        value=" P&L / Latest Audit Report (If Applicable)"
                      />
                      P&L / Latest Audit Report (If Applicable)
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="offset-sm-3 col-sm-9">
                  <p>
                    <small class="acs-red">* Required field</small>
                  </p>
                  <button
                    id="btn_submit"
                    type="submit"
                    class="btn btn-outline-primary"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Enquiry"
};
</script>
